<template>
  <div class="shift-container">
      <a-modal
      v-model="visibleModalManageInsentif"
      class="moda"
      :title="status.isEdit ? 'Form Ubah Insentif' : 'Form Tambah Insentif'"
      @cancel="toggleModalManageInsentif"
      centered
      >
        <form-insentif
          :newInsentif="newInsentif"
        />
        <template slot="footer">
          <a-button size="large" key="back" @click="toggleModalManageInsentif">
            Batal
          </a-button>
          <a-button size="large" key="submit" type="primary" :loading="loadingActionModalManageInsentif" @click="handleOkModalManageInsentif">
            {{status.isEdit ? 'Ubah' : 'Tambah'}} Sekarang
          </a-button>
        </template>
      </a-modal>
      <a-modal
      v-model="visibleModalManageEmployee"
      class="modal-shift"
      :title="`Atur Insentif ${manageEmployeeData.nama}`"
      @cancel="toggleModalManageEmployee"
      :destroyOnClose="true"
      centered
      >
        <manage-employee
          :manageEmployeeData="manageEmployeeData"
          @close-modal="toggleModalManageEmployee"
        />
        <template slot="footer">
          <a-button class="d-none m-0" size="large" key="back" @click="toggleModalManageEmployee">
            Tutup
          </a-button>
        </template>
      </a-modal>
      <div class="d-flex align-items center">
        <div class="ml-auto">
          <a-button @click.prevent="toggleModalManageInsentif('add')" size="large" type="primary"><a-icon type="plus" />Tambah Insentif</a-button>
        </div>
      </div>
      <div class="mt-4">
        <a-table
          :columns="insentifColumns"
          :data-source="insentifDataTable"
          :pagination="pagination"
          :loading="loadingTable"
          bordered
        >
          <div class="d-flex flex-column" slot="nama" slot-scope="value, record">
            <div class="mb-3">
              <span>{{value}}</span>
            </div>
            <div>
              <a-tag :color="record.status === 'draft_hr' ? 'red' : record.status === 'published_hr' || record.status === 'draft_finance' ? 'orange' : 'green'">
                {{record.status === 'published_finance' ? 'BERHASIL DIFINALISASI ADMIN KEUANGAN' : record.status === 'draft_finance' ? 'SEDANG DIPROSES ADMIN KEUANGAN' : record.status === 'published_hr' ? 'MENUNGGU PERSETUJUAN ADMIN KEUANGAN' : 'MENUNGGU DIFINALISASI ANDA'}}
              </a-tag>
            </div>
          </div>
          <div class="d-flex flex-column" slot="aksi" slot-scope="value, record">
            <!-- <a-button
              @click.prevent="toggleModalManageEmployee('open', record)"
              class="w-100 mb-3 text-primary border border-primary"
              size="large"
            >
              <a-icon type="team" />{{record.status === 'draft_hr' ? 'Finalisasikan' : 'Terfinalisasi'}}
            </a-button> -->
            <a-button
              @click.prevent="toggleModalManageEmployee('open', record)"
              class="w-100 mb-3 text-info border border-info"
              size="large"
            >
              <a-icon type="team" />{{record.status !== 'draft_hr' ? 'Lihat Pegawai' : 'Atur Pegawai'}}
            </a-button>
            <a-button
              :disabled="record.status !== 'draft_hr'"
              @click.prevent="toggleModalManageInsentif('edit', record)"
              class="w-100 mb-3 text-warning border border-warning"
              size="large"
            >
              <a-icon type="edit"/>Ubah
            </a-button>
            <a-button
              :disabled="record.status !== 'draft_hr'"
              @click.prevent="handleDeleteInsentif(record)"
              class="w-100 text-danger border border-danger"
              size="large"
            >
              <a-icon type="delete" />Hapus
            </a-button>
          </div>
        </a-table>
      </div>
  </div>
</template>

<script>
import moment from 'moment'
const formInsentif = () => import('@/components/app/AdminHR/Insentif/FormInsentif')
const manageEmployee = () => import('@/components/app/AdminHR/Insentif/ManageEmployee')

const insentifColumns = [
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Total Pegawai',
    dataIndex: 'total_pegawai',
    key: 'total_pegawai',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'total_pegawai' },
  },
  {
    title: 'Tanggal Dibuat',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]

export default {
  components: {
    formInsentif,
    manageEmployee,
  },
  data() {
    return {
      insentifColumns,
      loadingTable: false,
      loadingDeleteInsentif: false,
      visibleModalManageInsentif: false,
      visibleModalManageEmployee: false,
      loadingActionModalManageInsentif: false,
      insentifDataTable: [],
      pagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newInsentif: {
        nama: null,
      },
      idEdit: null,
      manageEmployeeData: {
        nama: null,
      },
    }
  },
  methods: {
    moment,
    toggleModalManageInsentif(status, data) {
      this.visibleModalManageInsentif = !this.visibleModalManageInsentif
      if (!this.visibleModalManageInsentif) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newInsentif = {
            nama: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newInsentif = {
            nama: data.nama,
          }
        }
      }
    },
    handleOkModalManageInsentif() {
      const content = `${this.status.isEdit ? 'Apakah anda yakin ingin mengubah insentif ini? perubahan ini akan mempengaruhi sistem penggajian pegawai' : 'Apakah anda yakin ingin membuat insentif baru ini? perubahan ini akan disimpan kedalam sistem'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Peringatan',
          description: 'Insentif tidak dapat ditambahkan jika tidak ada nama insentif',
        })
      } else {
        this.$confirm({
          title: 'Peringatan',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageInsentif = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataInsentif: this.newInsentif,
            } : {
              dataInsentif: this.newInsentif,
            }
            console.log('payload', payload)
            this.$store.dispatch(`insentif/${this.status.isEdit ? 'EDIT' : 'POST'}_INSENTIF`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageInsentif = false
                this.toggleModalManageInsentif()
                this.fetchDataInsentif()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Insentif berhasil diubah' : 'Insentif baru berhasil ditambahkan'
                  this.$notification.success({
                    message: 'Berhasil',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Insentif gagal diubah' : 'Insentif baru gagal ditambahkan'
                  this.$notification.error({
                    message: 'Gagal',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Ubah Sekarang' : 'Tambah Sekarang',
          cancelText: 'Batal',
        })
      }
    },
    handleDeleteInsentif(data) {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>Apakah anda yakin ingin menghapus insentif <b>{data.nama}</b> ? perubahan ini akan mempengaruhi sistem penggajian pegawai</div>
        ),
        onOk: () => {
          this.loadingTable = true
          this.loadingDeleteInsentif = true
          this.$store.dispatch('insentif/DELETE_INSENTIF', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataInsentif()
              this.loadingDeleteInsentif = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description:
                    'Insentif berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'Insentif gagal dihapus',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteInsentif = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Hapus',
        cancelText: 'Batal',
      })
    },
    fetchDataInsentif(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTable = true
      this.$store.dispatch('insentif/FETCH_INSENTIF', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.loadingTable = false
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          console.log(res.insentif)
          this.insentifDataTable = res.insentif.map(el => {
            return {
              key: el.id,
              ...el,
              total_pegawai: el.insentif_pegawais.length,
              createdAt: moment(el.createdAt).locale('id').format('DD MMMM YYYY'),
            }
          })
        })
    },
    toggleModalManageEmployee(status, data) {
      this.visibleModalManageEmployee = !this.visibleModalManageEmployee
      if (!this.visibleModalManageEmployee) {
        setTimeout(() => {
          this.fetchDataInsentif()
          this.manageEmployeeData = {
            nama: null,
          }
        }, 500)
        return ''
      }

      if (status) {
        this.manageEmployeeData = data
      }
    },
  },
  mounted() {
    this.fetchDataInsentif()
  },
  computed: {
    isValidateForm() {
      if (this.newInsentif.nama) {
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss">
.modal-shift{
  .ant-modal-wrap {
    .ant-modal {
      width: 95% !important;
      // height: 95% !important;
    }
  }
}
.modal-add-employee{
  .ant-modal-wrap {
    .ant-modal {
      width: 70% !important;
      // height: 95% !important;
    }
  }
}
// }

</style>
